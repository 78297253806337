/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "scss/all";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

:root {
  --name-colors: #f44336 #e91e63 #9c27b0 #673ab7 #3f51b5 #2196f3 #03a9f4 #00bcd4
    #009688 #4caf50 #8bc34a #cddc39 #ffcb30 #ffc107 #ff9800 #ff5722 #795548
    #9e9e9e #607d8b #000000;
  --primary: #143cdb;
  --primary-rgb: 20, 60, 219;
  --secondary: #fec310;
  --white: #ffffff;
  --black: #0a0a0a;
  --green: #1abc9c;
  --orange: #f39c12;
  --yellow: #f1c40f;
  --red: #e74c3c;
  --blue: #2980b9;
  --purple: #9b59b6;
  --almost-white: #f8f8f8;
  --smooth-grey: #e8e8e8;
  --light-grey: #bdc3c7;
  --grey: #666666;
  --dark-grey: #3f3f3f;
}
