@use "@angular/material" as mat;

@include mat.core();

// http://mcg.mbitson.com/#!?mcgpalette0=%23fd5c63&themename=wc2022
/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
$mat-primary: (
  50: #e3e8fb,
  100: #b9c5f4,
  200: #8a9eed,
  300: #5b77e6,
  400: #3759e0,
  500: #143cdb,
  600: #1236d7,
  700: #0e2ed2,
  800: #0b27cd,
  900: #061ac4,
  A100: #edeeff,
  A200: #babfff,
  A400: #878fff,
  A700: #6e78ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-secondary: (
  50: #fff8e2,
  100: #ffedb7,
  200: #ffe188,
  300: #fed558,
  400: #fecc34,
  500: #fec310,
  600: #febd0e,
  700: #feb50c,
  800: #feae09,
  900: #fda105,
  A100: #ffffff,
  A200: #fff9f2,
  A400: #ffe4bf,
  A700: #ffdaa5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$wc2022-app-primary: mat.define-palette($mat-primary);
$wc2022-app-accent: mat.define-palette($mat-secondary);

// The warn palette is optional (defaults to red).
$wc2022-app-warn: mat.define-palette(mat.$deep-orange-palette);

// Create the theme object (a Sass map containing all of the palettes).
$wc2022-app-theme: mat.define-light-theme(
  $wc2022-app-primary,
  $wc2022-app-accent,
  $wc2022-app-warn
);

$dark-primary: mat.define-palette($wc2022-app-primary);
$dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$dark-warn: mat.define-palette(mat.$deep-orange-palette);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
  )
);

.unicorn-dark-theme {
  @include mat.all-component-colors($dark-theme);
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($wc2022-app-theme);

// // Define an alternate dark theme.
// $dark-primary: mat.define-palette(mat.$blue-grey-palette);
// $dark-accent:  mat.define-palette(mat.$amber-palette, A200, A100, A400);
// $dark-warn:    mat.define-palette(mat.$deep-orange-palette);
// $dark-theme:   mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

// // Include the alternative theme styles inside of a block with a CSS class. You can make this
// // CSS class whatever you want. In this example, any component inside of an element with
// // `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
// .unicorn-dark-theme {
//   @include mat.all-component-themes($dark-theme);
// }

$wc2022-typography: mat.define-typography-config(
  $font-family: "Roboto, monospace",
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 500),
);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
// @include mat.typography-hierarchy($wc2022-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($wc2022-typography);
@include mat.core($wc2022-typography);
$primary: #56042c;
$secondary: #800080;
$white: #ffffff;
