.empty-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  img {
    padding: 3rem;
    height: 30vh;
  }
}
