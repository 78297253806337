.match-stats {
  display: flex;
  align-items: center;

  .stat-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 40px;

    img {
      height: 18px;
    }
  }
}
